<!--
 * @Author: 张博洋
 * @Date: 2021-10-25 11:43:46
 * @LastEditTime: 2021-11-11 11:52:56
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/check/detail.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="wrap full-h"
       style="background: #f5f5f5;">
    <!-- 头部 -->
    <div class="head">
      <div class="text">本测试预计需要3-5分钟，当前进度 <span>{{currentIndex}}/{{totalNum}}</span></div>
      <div class="bg mt8">
        <div class="progress_bar"
             style="width:0">
          <div class="linear-bg"></div>
        </div>
      </div>
    </div>
    <!-- 答题区域 -->
    <!-- :style="{opacity:item.isShow ? '1' : '0'}" -->
    <div class="question-area">
      <div class="question-wrap"
           :style="{'height':questionWrapHeight + 'px'}">
        <div class="item"
             v-for="(item,index) in questionsList"
             :key="index">
          <template v-if="item.isShow">
            <div class="question-title up">
              <div class="avatar">
                <img src="../../static/img/doc-avatar.png"
                     alt="">
              </div>
              <div class="question-loading"
                   v-if="item.loadingShow">
                <div class="loading">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div v-else
                   class="question-text fadeInLeft">
                {{item.content}}
              </div>
            </div>

            <!-- 题目标题 -->
            <div class="question-con"
                 v-if="item.questionShow"
                 :class="item.questionShow ? 'fadeInBottom' : ''">
              <div class="option"
                   v-for="(citem,cindex) in item.options"
                   :class="citem.active ? 'active' : ''"
                   :key="cindex"
                   @click="select(citem,item,index,cindex)">{{citem.content}}</div>
            </div>

            <!-- 答案 -->
            <div class="answer fadeInRight"
                 v-if="item.answerShow ">
              <div class="answer-con">{{options[index].content}}</div>
              <div class="avatar">
                <img src="../../static/img/patient-avatar.png"
                     alt="">
              </div>
            </div>
          </template>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      questionsList: [],
      options: [],
      questionWrapHeight: '100%',
      currentIndex: 0,
      totalNum: 20,
      progressWidth: '0',
      canClick: true,
      params: {
        patientId: this.$route.query.patientId,
        questionnaireId: this.$route.query.questionnaireId
      }
    }
  },
  created () {
    console.log(this.$route.query)
    if (this.$route.query.questionnaireId == '1') {
      this.$.setTitle('测一测你的焦虑程度')
    } else if (this.$route.query.questionnaireId == '2') {
      this.$.setTitle('测一测你的抑郁程度')
    }

    this.getQueryQuestions().then(() => {
      this.$set(this.questionsList[0], 'isShow', true)
      setTimeout(() => {
        this.$set(this.questionsList[0], 'loadingShow', false)
        this.$set(this.questionsList[0], 'questionShow', true)
      }, 2000)
    })

  },
  mounted () {
    // console.log(document.querySelector('.question-area').clientHeight)
    this.questionWrapHeight = document.querySelector('.question-area').clientHeight
    // console.log(document.querySelector('.head').clientHeight)
  },

  methods: {
    // 获取问题列表
    getQueryQuestions () {
      return new Promise((resolve) => {
        this.$axios({
          type: 'post',
          url: "question/queryQuestions",
          data: {
            questionnaireId: this.$route.query.questionnaireId
          },
          elseData: {
            loading: true
          },
          _self: this
        }).then(res => {
          this.questionsList = [...res.d.data]
          this.totalNum = res.d.total
          this.questionsList.forEach(item => {
            this.$set(item, 'loadingShow', true)
            this.$set(item, 'questionShow', false)
            this.$set(item, 'answerShow', false)
          })
          console.log(this.questionsList)
          console.log(res.d.data)
          resolve()
        })

      })

    },

    select (citem, item, index, cindex) {
      if (this.canClick) {
        // 只能点击一次
        this.canClick = false
        // 当前题号，用于顶部进度条
        this.currentIndex = index + 1
        // 点击选中效果
        this.$set(citem, 'active', true)
        // 每答一道题，push一道题的答案，用于提交
        this.options.push({
          content: citem.content,
          answerId: citem.id,
          questionId: citem.questionId
        })
        console.log(this.options)
        // 进度条动画
        $('.progress_bar').animate({
          width: (this.currentIndex / this.totalNum) * 100 + '%'
        }, 200)

        if (this.currentIndex === this.totalNum) {
          this.$set(item, 'questionShow', false)
          this.$set(item, 'answerShow', true)
          this.saveQuestion()
          return
        }
        // 点击效果，延迟
        setTimeout(() => {
          this.$set(item, 'questionShow', false)
          this.$set(item, 'answerShow', true)
          this.$set(this.questionsList[index + 1], 'isShow', true)
          this.$set(this.questionsList[index + 1], 'loadingShow', true)

          // this.progressWidth = (this.currentIndex / this.totalNum) * 100
        }, 400)

        // 下一题延迟loading
        setTimeout(() => {
          let nowDom = document.querySelector('.question-area').querySelectorAll('.item')[this.options.length]
          this.$set(this.questionsList[index + 1], 'loadingShow', false)
          this.$set(this.questionsList[index + 1], 'questionShow', true)
          // document.querySelector('.question-wrap').scrollTop
          let prevDomHeight = document.querySelector('.question-area').querySelectorAll('.item')[this.options.length - 1].clientHeight
          console.log(document.querySelector('.question-area').querySelectorAll('.item')[this.options.length - 1])
          console.log(prevDomHeight)
          this.questionWrapHeight = this.questionWrapHeight + prevDomHeight

          // console.log(nowDom)
          console.log(this.$.getPositiont(nowDom).top)

          console.log(prevDomHeight * (index + 1))
          $('.question-area').animate({
            scrollTop: this.$.getPositiont(nowDom).top - 57
          }, 500)
          this.canClick = true
        }, 2000)
      }

    },
    saveQuestion () {
      this.$axios({
        type: 'post',
        url: "question/saveQuestion",
        data: {
          patientId: this.$route.query.patientId,
          questionnaireId: this.$route.query.questionnaireId,
          options: this.options
        },
        elseData: {
          loading: true
        },
        _self: this
      }).then(res => {
        console.log(res)
        this.$router.replace({
          name: 'check.result',
          query: {
            questionnaireId: this.$route.query.questionnaireId,
            questionUserRefId: res.d.questionUserRefId
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
}
.head {
  background: #fff;
  padding: 12px 0;
  .text {
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    text-align: center;
    span {
      color: rgba(255, 130, 0, 1);
    }
  }
  .bg {
    height: 8px;
    margin: 0 37px;
    background: rgba(127, 180, 157, 0.2);
    position: relative;
    border-radius: 5px;
    .progress_bar {
      height: 100%;
      position: absolute;
      z-index: 9;
      left: 0;
      top: 0;
      background: rgb(15, 150, 91);
      background-image: repeating-linear-gradient(
        120deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1),
        6px,
        transparent 0px,
        transparent 11px
      );

      border-radius: 5px;
      position: relative;
      overflow: hidden;
      .linear-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(26, 165, 89, 0.1) 0%,
          rgba(56, 218, 169, 0.7) 100%
        );
        opacity: 1;
      }
    }
  }
}
.question-area {
  margin: 0 16px 16px;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  .question-wrap {
    .item {
      .question-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 16px;
        .avatar {
          width: 40px;
          height: 40px;

          img {
            width: 40px;
            height: 40px;
          }
        }
        .question-loading {
          min-height: 36px;
          background: #ffffff;
          border-radius: 4px;
          margin-left: 8px;
          display: flex;
          align-items: center;

          .loading {
            display: flex;
            padding: 8px 12px;
            justify-content: space-around;

            span {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #1ab370;
              margin: 2px;
            }
            span:nth-child(1) {
              animation: loading 0.8s linear 0s infinite alternate-reverse;
            }
            span:nth-child(2) {
              animation: loading 0.8s linear 0.2s infinite alternate-reverse;
            }
            span:nth-child(3) {
              animation: loading 0.8s linear 0.4s infinite alternate-reverse;
            }
          }
        }
        .question-text {
          background: #fff;
          padding: 8px 12px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          border-radius: 4px;
          margin-left: 8px;
          display: flex;
          align-items: center;
          font-weight: 400;
          color: #333333;
        }
      }
      .question-con {
        opacity: 1;
        padding: 20px 16px;
        background: #fff;
        margin-top: 26px;
        border-radius: 8px;
        .option {
          border-radius: 22px;
          border: 1px solid #e5e5e5;
          margin-bottom: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          padding: 12px 24px;
        }
        .option:last-child {
          margin-bottom: 0;
        }
        .option.active {
          border: 1px solid rgba(26, 179, 112, 0.6);
          background: rgba(26, 179, 112, 0.1);
          color: rgba(26, 179, 112, 0.6);
        }
      }
      .answer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 22px;
        .avatar {
          width: 40px;
          height: 40px;

          img {
            width: 40px;
            height: 40px;
          }
        }
        .answer-con {
          background: rgba(26, 179, 112, 1);
          padding: 8px 12px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          border-radius: 4px;
          margin-left: 8px;
          display: flex;
          align-items: center;
          font-weight: 400;
          color: #fff;
          margin-right: 8px;
        }
      }
    }
  }
}

.up {
  animation: up 0.8s 1;
}
// 问题出现动画
.fadeInLeft {
  transform-origin: 0 50%;
  animation: fadeIn 0.4s ease-in;
}
.fadeInBottom {
  transform-origin: 50% 100%;
  animation: fadeInBottom 0.4s ease-in;
}

// 答案出现动画
.fadeInRight {
  transform-origin: 100% 0%;
  animation: fadeInRight 0.4s ease-in;
}

.opacity1 {
  opacity: 1;
}

// loading动画
@keyframes loading {
  0% {
    transform: translateY(4px);
  }

  25% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-4px);
  }

  75% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(4px);
  }
}

// loading向上动画
@keyframes up {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}

// 题目出现动画
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

// 题目出现动画
@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

// 题目出现动画
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
</style>